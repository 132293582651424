import "./Footer.css";
import { useParams } from "react-router-dom";
import Regionalization from "./../../regionalization.json";
import Wave from "../../resources/imagenes/wave.png";
import Facebook from "../../resources/imagenes/social/facebook.png";
import Twitter from "../../resources/iconos/twitter.png";
import Instagram from "../../resources/imagenes/social/instagram.png";
import Tiktok from "../../resources/imagenes/social/tiktok.png";
import Linkedin from "../../resources/iconos/linkedin.png";
import Youtube from "../../resources/iconos/youtube.png";

function Footer() {
  const { country } = useParams();
  const config = Regionalization[country || "mx"];
  const year = new Date().getFullYear();

  return (
    <div className="footer">
      <div className="footer-content">
        <div className="container">
          <h1 className="title-footer">
            <span className="title-footer-plus">+</span> <span className="title-footer-bold">{config.footer1}</span> {config.footer2}<br />{config.footer3}
          </h1>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://atento.com/es/"
            className="btn-click-footer"
          >
            {config.footerButton}
          </a>
        </div>
        <div className="wrapper">
          <div className="footer-background">
            <img src={Wave} className="stretch" alt="" />
            <div className="wrapper-background" />
          </div>
          <div className="container2">
            <div className="redes-container">
              <p className="title-redes">{config.rrssTitle}</p>
              <div className="iconos-container">
                {config.facebook && (
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={config.facebook}>
                    <img src={Facebook} alt="facebook" />
                  </a>
                )}
                {config.twitter && (
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={config.twitter}>
                    <img src={Twitter} alt="twitter" />
                  </a>
                )}
                {config.instagram && (
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={config.instagram}>
                    <img src={Instagram} alt="instagram" />
                  </a>
                )}
                {config.tiktok && (
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={config.tiktok}>
                    <img src={Tiktok} alt="tiktok" />
                  </a>
                )}
                {config.youtube && (
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={config.youtube}>
                    <img src={Youtube} alt="youtube" />
                  </a>
                )}
                {config.linkedin && (
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={config.linkedin}>
                    <img src={Linkedin} alt="linkedin" />
                  </a>
                )}
              </div>
            </div>
            <div className="encuentras-mas">
              <div className="encuentras-mas1">{config.footer4}</div>
              <div>
                <span className="encuentras-mas2">+</span>
                <span className="encuentras-mas3"> {config.footer5}</span>
              </div>
              <div className="encuentras-mas4">{config.footer6}</div>
            </div>
          </div>
        </div>
        <a
          rel="noopener noreferrer"
          target="_blank"
          className="derechos-footer"
          href="https://atento.com/es/politica-de-privacidad/"
        >
          <h2>{config.copyright} {`${year}`}</h2>
        </a>
      </div>
    </div>
  );
}

export default Footer;
