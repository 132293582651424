import { useState, useEffect } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
import Contact from "../../componentes/Contact/Contact";
import Footer from "../../componentes/Footer/Footer";
import Loading from "../../resources/svg/loading.svg";
import Lupa from "../../resources/iconos/lupa.svg";
import AtentoLogo from "../../resources/iconos/nuevologo.svg";
import Regionalization from "./../../regionalization.json";
import "./Fulljobs.css";

function FullJobs() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <Contact />
      <FullJobsHeader />
      <FullJobsCard />
      <Footer />
    </div>
  );
}

function FullJobsCard() {
  const { country } = useParams();
  const config = Regionalization[country || "mx"];

  const [selectedTopic, setSelectedTopic] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [filteredJobs, setFilteredJobs] = useState();
  const [showAll, setShowAll] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`https://api2.worcket.com/public/company/opportunitiesbyname/${config.companyName}`)
      .then(response => response.json())
      .then(data => {
        data.reverse();
        setData(data);
      });
  }, [country]);

  const search = () => {
    let jobs = data;
    const topic = selectedTopic.trim().toLowerCase().split(/\s+/).filter(Boolean);
    const city = selectedCity.trim().toLowerCase().split(/\s+/).filter(Boolean);

    if (topic.length) {
      jobs = jobs.filter(job => {
        const title = job.title.toLowerCase();
        return topic.every(t => title.includes(t));
      });
    }

    if (city.length) {
      jobs = jobs.filter(job => {
        if (job.geolocations.length > 0) {
          const jobCity = job.geolocations[0].display_name.toLowerCase();
          return city.every(t => jobCity.includes(t));
        }
      });
    }

    setShowAll(false);
    setFilteredJobs(jobs);
  };

  const VACANTES_PER_PAGE = 12;
  const showVacantes = list => {
    if (showAll || list.length <= VACANTES_PER_PAGE)
      return list.map(job => <Vacante key={job.id} job={job} />);

    list = list.slice(0, VACANTES_PER_PAGE);
    return (
      <>
        {list.map(job => <Vacante key={job.id} job={job} />)}
        <div className="centered">
          <button
            className="search-button"
            onClick={() => setShowAll(true)}>
            {config.showAll}
          </button>
        </div>
      </>
    );
  };

  return (
    <div className="CC-fulljobs">
      {data.length > 0 && (
        <>
          <div className="fulljobs-buscador-container">
            <p className="fulljobs-title">{config.searchTitle}</p>
            <div className="fulljobs-buscador">
              <div className="fulljobs-buscador-input">
                <img height="40px" src={Lupa} alt="search" />
                <input className="search-input"
                  type="search"
                  placeholder={config.input1}
                  value={selectedTopic}
                  onChange={e => setSelectedTopic(e.target.value)} />
              </div>
              <div className="fulljobs-buscador-input">
                <img height="40px" src={Lupa} alt="search" />
                <input className="search-input"
                  type="search"
                  placeholder={config.input2}
                  value={selectedCity}
                  onChange={e => setSelectedCity(e.target.value)} />
              </div>
              <div>
                <button className="search-button"
                  onClick={search}>{config.searchButton}</button>
              </div>
            </div>
          </div>
          <p className="fulljobs-title2">{config.title1}<br />{config.title2} <strong>{config.title3}</strong></p>
        </>
      )
      }
      <div className="fulljobs-container">
        {data.length > 0 ? (
          filteredJobs ? (
            filteredJobs.length > 0 ? (
              showVacantes(filteredJobs)
            ) : (
              <h2>No hay coincidencias con su búsqueda</h2>
            )
          ) : (
            showVacantes(data)
          )
        ) : (
          <img width="50px" src={Loading} alt="loading" />
        )}
      </div>
    </div >
  );
}

function Vacante({ job }) {
  const { country } = useParams();
  const config = Regionalization[country || "mx"];

  return (
    <div className="fulljobs-card" key={job.id}>
      <div className="fulljobs-image">
        <img
          className="fj-img-card"
          src={job.image || job.companyLogo}
          alt="companyLogo"
        />
      </div>
      <div className="text-card-container">
        <h3 className="title-card-fj">{job.title}</h3>
        <h5 className="city-card-fj">
          {job.geolocations.length > 0
            ? job.geolocations[0].display_name
            : null}
        </h5>
        <h5 className="type-card-fj">
          {job.jobType.charAt(0).toUpperCase() + job.jobType.slice(1)}
        </h5>
      </div>
      <div className="fj-card-footer">
        <a
          href={`https://apply.worcket.com/?id=${job.id}`}
          rel="noopener noreferrer"
          target="_blank">
          <button className="btn-card-fj">{config.apply}</button>
        </a>
      </div>
    </div>
  );
}

function FullJobsHeader() {
  const { country } = useParams();
  const config = Regionalization[country || "mx"];

  return (
    <div className="header-container">
      <div className="atento-title">
        <div>
          <Link to="">
            <img className="logo-jobs" src={AtentoLogo} alt="Atento logo" />
          </Link>
        </div>
        <div className="header-buttons">
          <a
            className={!config.websiteLink ? "hidden" : ""}
            href={config.websiteLink}
            rel="noopener noreferrer"
            target="_blank">
            <button className="website-button">{config.website}</button>
          </a>
          <a
            href={config.signupLink}
            rel="noopener noreferrer"
            target="_blank">
            <button className="signup-button">{config.signup}</button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default FullJobs;