import "./Contact.css";
import Regionalization from "./../../regionalization.json";
import Mes from "../../resources/iconos/atobot.svg";
import Whatsapp from "../../resources/iconos/whatsapp.svg";
import { useParams } from "react-router-dom";

function Contactos() {
  const { country } = useParams();
  const links = Regionalization[country || "mx"];

  return (
    <div className="overlay">
      {links.messenger && (
        <div>
          <a
            rel="noopener noreferrer"
            target="_blank"
            className="nounderline"
            href={links.messenger}>
            <img className="over-icon" src={Mes} alt="atobot" />
          </a>
        </div>
      )}
      {links.whatsapp && (
        <div className="over">
          <a
            rel="noopener noreferrer"
            target="_blank"
            className="nounderline"
            href={links.whatsapp}>
            <img className="over-icon" src={Whatsapp} alt="whatsapp" />
          </a>
        </div>
      )}
    </div>
  );
}

export default Contactos;
